import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"ExpansionComponent ExpansionBordered mt-6"},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle",attrs:{"expand-icon":""},on:{"click":function($event){return _vm.changeStateExpansive()}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"iconExpand"},[_vm._v(" "+_vm._s(_vm.iconExpansive)+" ")])]},proxy:true}])},[_vm._v(" General Report "),_c('div',{staticClass:"ExpansionState HideOnMovil"},[_vm._v(" "+_vm._s(_vm.stateExpansiveMessage)+" ")])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent justify-space-between"},[_c('div',{staticClass:"downloadAccouting d-flex justify-start align-center"},[_vm._v("Download "),_c('b',[_vm._v("SLU Account Summary")]),_vm._v(" Documents")]),_c('div',{staticClass:"DownloadButtons d-flex justify-start align-center"},[_c(VBtn,{staticClass:"Btn",attrs:{"text":"","rounded":""}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v(" mdi-arrow-expand-down ")]),_vm._v(" Download PDF ")],1),_c(VBtn,{staticClass:"Btn",attrs:{"text":"","rounded":"","loading":_vm.loading},on:{"click":function($event){return _vm.downloadExcel()}}},[_c(VIcon,{staticClass:"mr-3"},[_vm._v(" mdi-arrow-expand-down ")]),_vm._v(" Download xlxs ")],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }